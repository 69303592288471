import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";


import ConfigStore from "./Common/ConfigStore";
const PdfAnnotator = React.lazy(() => import('./Collect/common/pdf-annotator/PdfAnnotator'));

// = = = TO_REVISE
const AssetsPage = React.lazy(() => import('./Pages/Assets/AssetsPage'));
const GDPRQuestionaire = React.lazy(() => import('./Pages/GDPR/Questionaire/GDPRQuestionaire'));
const AssetsAnalysisPage = React.lazy(() => import('./Pages/AssetsAnalysis/AssetsAnalysisPage'));

// = = = ANALYZE
const DashboardsPage = React.lazy(() => import('./Analyze/Dashboards/DashboardsPage'));
const SubcontractorsPage = React.lazy(() => import('./Analyze/Subcontractors/SubcontractorsPage'));

// = = = AUTH
const Login = React.lazy(() => import('./Auth/Login'));
const LoginProcore = React.lazy(() => import('./Auth/LoginProcore'));

// = = = AUTOMATE
const AutomateWorkflowsExecutionsList = React.lazy(() => import('./Automate/Automate/WorkflowsExecutions/AutomateExecutionsList'));
const AutomateWorkflowsView = React.lazy(() => import('./Automate/Automate/Workflows/View'));
const AutomateWorkflowsList = React.lazy(() => import('./Automate/Automate/Workflows/List'));

const ExtractDocumentTree = React.lazy(() => import('./Automate/Automate/ExtractDocumentTree'));

const AutomateWorkflowTemplateList = React.lazy(() => import('./Automate/Automate/Templates/AutomateWorkflowTemplateList'));
const AutomateWorkflowTemplateView = React.lazy(() => import('./Automate/Automate/Templates/AutomateWorkflowTemplateView'));
// = = = COLLECT

const FormBuilderList = React.lazy(() => import('./Collect/MyForms/list/FormBuilderList'));
const FormBuilder = React.lazy(() => import('./Collect/MyForms/form/FormBuilder'));

const FormSubmissionList = React.lazy(() => import('./Collect/Submissions/list/FormSubmissionList'));
const CollectSubmissionsList = React.lazy(() => import('./Collect/Submissions/list-v2/CollectSubmissionsList'));
const CollectSubmissionHubView = React.lazy(() => import('./Collect/Submissions/view-v2/CollectSubmissionHubView'));
const FormSubmissionView = React.lazy(() => import('./Collect/Submissions/view/FormSubmissionView'));

const CollectAnalytics = React.lazy(() => import('./Collect/Analytics/CollectAnalytics'));

// = = = COMMON
const LayoutEmpty = React.lazy(() => import('./Common/LayoutEmpty/LayoutEmpty'));
const BaseLayout = React.lazy(() => import('./Common/Layout/BaseLayout'));

const NotificationComponent = React.lazy(() => import('./Common/Notifications/NotificationComponent'));

// = = = ENABLE
const BusinessProcessManagementListPage = React.lazy(() => import('./Enable/BusinessProcessManagement/List/BusinessProcessManagementListPage'));
const BusinessProcessManagementPage = React.lazy(() => import('./Enable/BusinessProcessManagement/View/BusinessProcessManagementPage'));
const BusinessProcessLessonLearnPage = React.lazy(() => import('./Enable/BusinessProcessManagement/View/BusinessProcessLessonLearnPage'));

const LessonLearnList = React.lazy(() => import('./Enable/BusinessProcessManagement/LessonLearn/List/LessonLearnList'));
const LessonLearnPage = React.lazy(() => import('./Enable/BusinessProcessManagement/LessonLearn/LessonLearnPage'));


const RiskAssessmentManagementPage = React.lazy(() => import('./Enable/RiskAssessmentManagement/View/RiskAssessmentManagementPage'));
const RiskAssessmentManagementListPage = React.lazy(() => import('./Enable/RiskAssessmentManagement/List/RiskAssessmentManagementListPage'));

const EnableCorporateRepository = React.lazy(() => import('./Enable/BusinessProcessManagement/CorporateRepository/CorporateRepository'));
const EnableCorporateRepositoryPreview = React.lazy(() => import('./Enable/BusinessProcessManagement/CorporateRepository/CorporateRepositoryPreview'));

// = = = HOME
const HomePage = React.lazy(() => import('./Home/HomePage'));

// = = = REPORTS
const WeeklyReviewReport = React.lazy(() => import('./Reports/WeeklyReviewReport/WeeklyReviewReport'));
const RiskStatusReport = React.lazy(() => import('./Reports/RiskStatusReport/RiskStatusReport'));

// = = = SETTINGS
const AssetsDefinitionsList = React.lazy(() => import('./Settings/AssetsDefinition/AssetsDefinitionList'));
const EvidenceManagerList = React.lazy(() => import('./Settings/EvidenceManager/List'));
const TenantsList = React.lazy(() => import('./Settings/Tenants/List'));
const View = React.lazy(() => import('./Settings/Tenants/View'));

const ProjectList = React.lazy(() => import('./Settings/Projects/List'));
const ProjectView = React.lazy(() => import('./Settings/Projects/ProjectView'));

// = = = UTILS
const InitDemo = React.lazy(() => import('./Other/BpmnSvgGenerator/InitDemo'));
const BowtieSvgGenerator = React.lazy(() => import('./Other/BowtieSvgGenerator/BowtieSvgGenerator'));

const ViewBuilder = React.lazy(() => import('./ViewBuilder/ViewBuilder'));
const ViewGenerator = React.lazy(() => import('./ViewGenerator/ViewGenerator'));

// = = = WORKFLOW_RISK_CONTROL
const DocumentsPage = React.lazy(() => import('./WorflowRiskControl/Documents/DocumentsPage'));
const ConsentPage = React.lazy(() => import('./WorflowRiskControl/Consents/ConsentPage'));
const TasksPage = React.lazy(() => import('./WorflowRiskControl/Tasks/TasksPage'));
const Register = React.lazy(() => import('./Auth/Register'));
const ResetPassword = React.lazy(() => import('./Auth/ResetPassword'));
const Activate = React.lazy(() => import('./Auth/Activate'));

const DxfEditor = React.lazy(() => import('./Other/DxfEditor/DxfEditor'));
const CommentList = React.lazy(() => import('./WorflowRiskControl/Comments/CommentList'));

const QueryBuilderDev = React.lazy(() => import('./Other/QueryBuilderDev'));
const AnalyzeDigitalTwins = React.lazy(() => import('./Analyze/DigitalTwins/AnalyzeDigitalTwins'));

const DocumentScannerComponent = React.lazy(() => import("./Scanner/DocumentScannerComponent"));
const ImageAnnotatorComponent = React.lazy(() => import("./Common/ImageAnnotator/ImageAnnotatorComponent"));
const EmptyPage = React.lazy(() => import("./EmptyPage"));

// I18N
import "moment/locale/es";
import "moment/locale/en-gb";
import { last } from "lodash";
import { Spin } from "antd";
import AppRoute from "./AppRoute";

const App = ({ t }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [appConfig, setAppConfig] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    fetch("appConfig.json").then((r) => r.json()).then((appConfigJson) => {
      setAppConfig(appConfigJson);
      localStorage.setItem("appConfig", JSON.stringify(appConfigJson))

      if (window.location.href.includes(`postlogin`)) {
        var token = last(window.location.href.split("/")).replace(`#`, ``);
        localStorage.setItem(`jwtToken`, token);

        localStorage.removeItem(`projectId`)
        localStorage.removeItem(`tenantId`)

        history.push('/');
        return window.location.reload();
      } else setIsLoading(false);
    });
  }, []);

  if (isLoading)
    return <div style={{ marginTop: '20%' }}>
      <div style={{ textAlign: "center" }}>
        <img src={"./Beawre_login_logo.png"} alt="Beawre" style={{ height: "42px", marginBottom: 30, marginTop: 40 }} />
      </div>
      <Spin spinning={true} size="large">
        <div style={{ height: 50, width: 50 }}>
          1
        </div>
      </Spin>
    </div>;

  const getDefaultRoute = () => {
    if (!localStorage.getItem(`jwtToken`)) return `/utils/login`;
    if (ConfigStore.platform === "mobile") return `/${localStorage.getItem(`projectName`)}/collect/entries`;
    if (localStorage.getItem(`jwtToken`) && localStorage.getItem(`projectName`) && localStorage.getItem(`projectName`) === "bas") return `/${localStorage.getItem(`projectName`)}/workflows`;
    return `/${localStorage.getItem(`projectName`)}${appConfig.router.defaultRoute}`;
  };

  const getUrlProjectPaths = (mainPath) => [`/:projectName?` + mainPath];

  const componentsRegistry = {
    AssetsPage: AssetsPage,
    AssetsAnalysisPage: AssetsAnalysisPage,
    DocumentsPage: DocumentsPage,
    DashboardsPage: DashboardsPage,
    AnalyzeDigitalTwins: AnalyzeDigitalTwins,

    SubcontractorsPage: SubcontractorsPage,
    HomePage: HomePage,
    GDPRQuestionaire: GDPRQuestionaire,
    WeeklyReviewReport: WeeklyReviewReport,
    RiskStatusReport: RiskStatusReport,
    BusinessProcessManagementListPage: BusinessProcessManagementListPage,
    BusinessProcessManagementPage: BusinessProcessManagementPage,
    BusinessProcessLessonLearnPage: BusinessProcessLessonLearnPage,
    RiskAssessmentManagementPage: RiskAssessmentManagementPage,
    RiskAssessmentManagementListPage: RiskAssessmentManagementListPage,

    EnableCorporateRepository,
    EnableCorporateRepositoryPreview,

    AssetsDefinitionsList: AssetsDefinitionsList,
    ConsentPage: ConsentPage,
    TasksPage: TasksPage,
    EvidenceManagerList: EvidenceManagerList,

    ProjectList: ProjectList,
    ProjectView: ProjectView,
    TenantsView: View,
    TenantsList,

    AutomateWorkflowsList: AutomateWorkflowsList,
    AutomateWorkflowsExecutionsList: AutomateWorkflowsExecutionsList,

    AutomateWorkflowTemplateList,
    AutomateWorkflowTemplateView,

    ExtractDocumentTree: ExtractDocumentTree,

    FormBuilderList: FormBuilderList,
    FormBuilder: FormBuilder,

    FormSubmissionList: FormSubmissionList,
    CollectSubmissionsList: CollectSubmissionsList,
    FormSubmissionView: FormSubmissionView,

    PdfAnnotator,

    LessonLearnList,
    LessonLearnPage,
    CommentList,

    NotificationComponent,

    QueryBuilderDev,

    DocumentScannerComponent: DocumentScannerComponent,
    ImageAnnotatorComponent,
    ViewBuilder,
    ViewGenerator,
    EmptyPage
  };

  return (
    <div>
      {!isLoading && (
        <Switch>
          {appConfig.router && appConfig.router.paths.map((e) => <AppRoute key={e.path} exact={false} layout={BaseLayout} title={t(e.title)} path={getUrlProjectPaths(e.path)} component={componentsRegistry[e.component]} />)}
          <AppRoute key="automate" exact layout={BaseLayout} title={t("mainMenu.automate")} path={getUrlProjectPaths("/automate/workflows")} component={AutomateWorkflowsList} />
          <AppRoute key="automate" exact layout={BaseLayout} title={t("mainMenu.automate")} path={getUrlProjectPaths("/automate/workflow/:id")} component={AutomateWorkflowsView} />
          <AppRoute key="automate-exe" exact layout={BaseLayout} title={t("automate.AutomateExecutions")} path={getUrlProjectPaths("/automate/workflows/executions")} component={AutomateWorkflowsExecutionsList} />
          <AppRoute key="automate-text" exact layout={BaseLayout} title="Automate text" path={getUrlProjectPaths("/automate/test")} component={ExtractDocumentTree} />

          <AppRoute key="document-scanner" exact layout={BaseLayout} title="testing Document Scanner" path="/documentscanner" component={DocumentScannerComponent} />

          <AppRoute key="automate-template-list" exact layout={BaseLayout} title={t("mainMenu.automate")} path={getUrlProjectPaths("/automate/templates")} component={AutomateWorkflowTemplateList} />
          <AppRoute key="automate-template-view" exact layout={BaseLayout} title={t("mainMenu.automate")} path={getUrlProjectPaths("/automate/template/:id")} component={AutomateWorkflowTemplateView} />

          {/* = = = AUTH = = = */}
          <AppRoute key="login" exact layout={LayoutEmpty} title="Login" path="/utils/login" component={Login} />
          <AppRoute key="register" exact layout={LayoutEmpty} title="Register" path="/utils/register" component={Register} />
          <AppRoute key="reset-password" exact layout={LayoutEmpty} title="Reset password" path="/utils/reset-password/:code?" component={ResetPassword} />
          <AppRoute key="login-post" exact layout={LayoutEmpty} title="Login" path="/utils/login/:jwtToken" component={Login} />
          <AppRoute key="activate" exact layout={LayoutEmpty} title="Activate" path="/utils/activate/:code" component={Activate} />

          <AppRoute key="login-procore" exact layout={LayoutEmpty} title="Procore Login" path="/utils/procore/login-complete/:jwtToken" component={LoginProcore} />
          <AppRoute key="login-procore-pre" exact layout={LayoutEmpty} title="Procore login" path="/utils/procore/login/:tenantId" component={LoginProcore} />

          {/* = = = COLLECT = = = */}
          <AppRoute key="collect_forms_list" exact layout={BaseLayout} title={t("mainMenu.myForms")} path={getUrlProjectPaths("/collect/forms")} component={FormBuilderList} />
          <AppRoute key="collect_forms_view" exact layout={BaseLayout} title={t("collect.myFormsNew")} path={getUrlProjectPaths("/collect/forms/:templateId")} component={FormBuilder} />

          <AppRoute key="collect_submissions_list" exact layout={BaseLayout} title={t("mainMenu.entries")} path={getUrlProjectPaths("/collect/entries")} component={CollectSubmissionsList} />
          <AppRoute key="collect_submissions_hub_view" exact layout={BaseLayout} title={t("collect.modifyEntry")} path={getUrlProjectPaths("/collect/entry/hub/:assetId/:containerId")} component={CollectSubmissionHubView} />
          <AppRoute key="collect_submissions_view" exact layout={BaseLayout} title={t("collect.modifyEntry")} path={getUrlProjectPaths("/collect/entry/:id")} component={FormSubmissionView} />

          <AppRoute key="collect_analytics" exact layout={BaseLayout} title={t("collect.analytics")} path={getUrlProjectPaths("/collect/analytics")} component={CollectAnalytics} />
          {/* = = = UTILS = = = */}
          <AppRoute key="/utils/init-demo/multiple" exact layout={LayoutEmpty} title="Init demo data" path="/utils/init-demo" component={InitDemo} />
          <AppRoute key="/utils/bpmn" exact layout={LayoutEmpty} title="Generate BPMN SVG" path="/utils/generate-svg/bpmn/:id/:jwtToken" component={InitDemo} />
          <AppRoute key="/utils/bpmn/1" exact layout={LayoutEmpty} title="Generate BPMN SVG" path="/utils/generate-svg/bpmn/:id" component={InitDemo} />
          <AppRoute key="/utils/bowtie" exact layout={LayoutEmpty} title="Generate Bowtie SVG" path="/utils/generate-svg/bowtie/:id/:jwtToken" component={BowtieSvgGenerator} />
          <AppRoute key="/utils/dxf" layout={BaseLayout} title="Dxf Editor" path="/utils/dxf/:containerId" component={DxfEditor} />
          <AppRoute key="/utils/query" layout={LayoutEmpty} title="Query" path="/utils/query" component={QueryBuilderDev} />

          <AppRoute key="/view-builder" layout={BaseLayout} title="View builder" path="/utils/view-builder" component={ViewBuilder} />
          {/* <AppRoute key={"view-builder-view-page"} exact={true} layout={LayoutEmpty} title={"test"} path={getUrlProjectPaths("/*")} component={QueryBuilderDev} /> */}

          {/* = = = COLLECT = = = */}
          <AppRoute key="/emptyPage" exact layout={LayoutEmpty} title="PDF testing" path="/utils/pdf" component={ViewGenerator} />
          <AppRoute key="/image-annot" exact layout={LayoutEmpty} title="Image anotr testing" path="/utils/image-annot" component={ImageAnnotatorComponent} />

          <AppRoute path="/404" layout={LayoutEmpty} title="Page not found" component={EmptyPage} />
          <AppRoute layout={BaseLayout} title="" component={ViewGenerator} />

          <Route path="/" render={() => <Redirect to={getDefaultRoute()} />} />
        </Switch>
      )}
    </div>
  );
}

export default withTranslation()(App);
