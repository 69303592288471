import { makeAutoObservable } from "mobx";

import env from './../env.json';

class ConfigStore {
  constructor() {
    makeAutoObservable(this);

    this.setBackendUrl();
    this.setPlatform();
  }

  backendUrl = "";
  backendUrlHost = "";
  backendHeaders = { mode: "no-cors" };

  isOffline = false;
  platform = undefined;

  getAppConfig() {
    return fetch("appConfig.json").then((r) => r.json());
  }

  setBackendUrl = (host) => {
    if (!host && localStorage.getItem(`host`)) host = localStorage.getItem(`host`);
    if (!host) host = env.ApiUrl;

    this.backendUrl = host + "/api/";
    this.backendUrlHost = host;

    localStorage.setItem(`host`, host);
    console.log('host?', host)
  }

  setPlatform = (platform = undefined) => {
    if (!platform && localStorage.getItem(`platform`)) platform = localStorage.getItem(`platform`);
    if (!platform) platform = env.Platform;

    this.platform = platform;

    localStorage.setItem(`platform`, this.platform);
  }

  getDefaultRoute = () => {
    const appConfig = JSON.parse(localStorage.getItem(`appConfig`));
    if (!localStorage.getItem(`jwtToken`))
      return `/utils/login`;

    console.log('get default route', localStorage.getItem(`projectName`), appConfig.router.defaultRoute, this.platform)

    if (this.platform === "mobile")
      return `/${localStorage.getItem(`projectName`)}/collect/entries`;

    if (localStorage.getItem(`jwtToken`) && localStorage.getItem(`projectName`) && localStorage.getItem(`projectName`) === "bas")
      return `/${localStorage.getItem(`projectName`)}/workflows`;

    return `/${localStorage.getItem(`projectName`)}${appConfig.router.defaultRoute}`;
  };
}

export default new ConfigStore();
