"use client";

import "antd/dist/reset.css"
import React, { Suspense, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import i18n from "./components/i18n";
import { I18nextProvider } from "react-i18next";
import { ConfigProvider, Spin } from "antd";
import { CookiesProvider } from 'react-cookie';

import "./index.css";
import { themeConfig } from "./Common/Layout/config";
import env from "./env.json";
import config from "devextreme/core/config";

import locale from 'antd/locale/en_GB';
import { ErrorBoundary } from "react-error-boundary";

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
// dayjs.extend(require('dayjs/plugin/isSameOrBefore'))

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

if (env.DevExtremeKey) {
  config({ licenseKey: env.DevExtremeKey })
}

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=60e94e44-79f2-4be8-8c45-0d8f6ac48de1;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();

ReactDOM.render(
  <Suspense fallback={<div style={{ marginTop: '20%' }}>
    <div style={{ textAlign: "center" }}>
      <img src={"./Beawre_login_logo.png"} alt="Beawre" style={{ height: "42px", marginBottom: 30, marginTop: 40 }} />
    </div>
    <Spin spinning={true} size="large">
      <div style={{ height: 50, width: 50 }}></div>
    </Spin>
  </div>}>
    {/* <AppInsightsErrorBoundary appInsights={reactPlugin}> */}
    <BrowserRouter basename={baseUrl}>
      <I18nextProvider i18n={i18n}>
        <CookiesProvider>
          <ConfigProvider theme={themeConfig()} locale={locale}>
            <App />
          </ConfigProvider>
        </CookiesProvider>
      </I18nextProvider>
    </BrowserRouter>
    {/* </AppInsightsErrorBoundary> */}
  </Suspense>,
  rootElement
);

registerServiceWorker();
