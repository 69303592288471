import { ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import ConfigStore from './ConfigStore';
import _, { cloneDeep } from 'lodash';
import { message } from 'antd';

import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { Observable } from 'rxjs';
import { logout } from './Users/Logout';

export default class GraphQlService {
  basePath = '';
  wsBasePath = '';
  accessToken = '';
  config = ConfigStore;

  constructor() {
    this.accessToken = localStorage.getItem('userId');
  }

  get = gqlString =>
    this.client().query({ query: this.stringToGql(gqlString), errorPolicy: 'all', fetchPolicy: 'no-cache' });

  post = (gqlString, variables) =>
    this.client().mutate({ mutation: this.stringToGql(gqlString), variables: variables, errorPolicy: 'all' });

  displayErrors = errors => {
    if (!errors) return;
    errors.forEach(error => {
      if (error.extensions) {
        if (error.extensions.data && error.extensions.data.Code) message.error(error.extensions.data.Code);
        if (error.extensions.data && error.extensions.data.CODE) message.error(error.extensions.data.CODE);
        if (error.extensions.code) message.error(error.extensions.code);
      }
    });
    return;
  };

  client = () => {
    this.basePath = this.config.backendUrl + `graphql`;

    const httpLink = new HttpLink({
      uri: this.basePath,
      fetchOptions: 'no-cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``)
      }
    });

    const auth = setContext((operation, context) => {
      const token = localStorage.getItem('jwtToken');
      if (token === null) {
        return {};
      } else {
        return {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``)
          }
        };
      }
    });

    const error = onError(({ graphQLErrors, networkError }) => {
      console.log('unauthorized', graphQLErrors, networkError);
      if (graphQLErrors) {
        console.log(`[other error]: `, graphQLErrors);
        if (
          (graphQLErrors[0].extensions && graphQLErrors[0].extensions.code === 'authorization') ||
          graphQLErrors[0].message.includes(`You are not authorized to run this query`)
        ) {
          console.log('unauthorized');
          logout();
        }
        if (
          graphQLErrors[0].extensions &&
          graphQLErrors[0].extensions.data &&
          graphQLErrors[0].extensions.data.CODE === 'UNAUTHORIZED'
        ) {
          message.error(`You are not authorized to do this!`);
        }
      }
      if (networkError) {
        console.log(`[Network error]:`, networkError);
        // logout();
      }
    });

    const ForwardExtensionsLink = new ApolloLink((operation, forward) =>
      forward(operation).map(response => {
        if (response.data) {
          if (response.extensions && response.extensions.totalEntries)
            response.data.totalEntries = response.extensions.totalEntries;
        }
        return response;
      })
    );

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([auth, error, ForwardExtensionsLink, httpLink])
    });
  };

  clientWs = () => {
    this.basePath = this.config.backendUrl + `graphql`;
    this.wsBasePath =
      this.config.backendUrlHost.replace('https://', 'wss://').replace('http://', 'wss://') + `/api/graphql`;

    const wsLink = new WebSocketLink({
      uri: this.wsBasePath,
      options: {
        reconnect: true,
        connectionParams: {
          Authorization: `Bearer ${localStorage.getItem(`jwtToken`)}`.replace(`#`, ``)
        }
      }
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        // ({ query }) => {
        //   const definition = getMainDefinition(query);
        //   console.log('def', definition)
        //   return (
        //     definition.kind === 'OperationDefinition' &&
        //     definition.operation === 'subscription'
        //   );
        // },
        wsLink
      ])
    });
  };

  stringToGql = string => {
    return gql`
      ${string}
    `;
  };

  deserializeObject(object) {
    object = _.cloneDeep(object);
    if (!_.isEmpty(object['payload'])) object['payload'] = JSON.parse(object['payload']);
    return object;
  }

  deserializeObjects(objects) {
    objects = _.cloneDeep(objects);
    objects.forEach((object, index) => {
      if (!_.isEmpty(object['payload'])) object['payload'] = JSON.parse(object['payload']);
    });
    return objects;
  }
  // New method for aiEngineInit mutation
  aiEngineInit(objectId, bowtie = {}) {
    console.log('sessionIdInit', objectId, bowtie);

    if (!bowtie) {
      bowtie = '';
    }

    const mutation = `
        mutation mutate($objectId: Guid, $bowtie: String) {
          aiEngineInit(objectId: $objectId, bowtieJson: $bowtie)
        }
      `;

    const variables = { objectId, bowtie };
    return this.post(mutation, variables);
  }
  // New method for welcome message
  aiWelcomeMessage(objectId) {
    // aiEngineMessages(sessionId: "b47cfe2d-63ea-4978-9d33-84263568dd14") { id role content createdOn }
    const mutation = `
         {
  __typename
  aiEngineMessages(sessionId: "${objectId}") {
    content
    role createdOn
  }
}
        `;
    // console.log('question', aiResponse.data.aiEngineMessageSend);

    // const variables = { objectId, bowtie };
    console.log('sessionId', mutation);

    return this.get(mutation);
  }
  // New method for aiEngineMessageSend mutation
  aiEngineMessageSend(sessionId, message, bowtieJson = {}) {
    console.log('sessionId', sessionId, message, bowtieJson);

    const mutation = `
        mutation mutate($sessionId: Guid, $message: String, $bowtieJson: String) {
          aiEngineMessageSend(sessionId: $sessionId, message: $message, bowtieJson: $bowtieJson) { content bowtie }
        }
      `;

    const variables = { sessionId, message, bowtieJson };
    return this.post(mutation, variables);
  }
}
