import { message } from 'antd';
import i18next from 'i18next';
import { makeAutoObservable, toJS } from 'mobx';
import BackendService from '../Common/BackendService';
import GraphQlService from '../Common/GraphQlService';

export class ViewGeneratorStore {
  isLoading = false;

  data = undefined;

  hubId = undefined;
  hubConfig = undefined;
  assetDefinition = undefined;

  tableConfig = { page: 1, pageSize: 20, total: 0, sorter: {} };

  basicSearchValue = undefined;
  advanceSearchDrawer = { isVisible: false };

  uiConfig = undefined;

  title = undefined;

  tableRowSelectionEnabled = false;

  containersApi = new BackendService('containers');

  currentPageContainerIds = [];
  selectedContainerIds = [];

  exportGridCallback = null;
  expandAllCallback = null;
  collpaseAllCallback = null;

  constructor() {
    makeAutoObservable(this);
  }

  prepareDataSource = () => {
    let dataSource = this.hubConfig.DataSource;
    console.log('tableConfig', toJS(this.hubConfig));

    if (this.tableConfig) {
      dataSource = dataSource.replace('$page', this.tableConfig.page);
      dataSource = dataSource.replace('$pageSize', this.tableConfig.pageSize);
      dataSource = dataSource.replace('$orderBy', JSON.stringify(this.tableConfig.sorter).replaceAll('"', '\\"'));
    } else {
      dataSource = dataSource.replace('$page', null);
      dataSource = dataSource.replace('$pageSize', null);
      dataSource = dataSource.replace('$orderBy', '');
    }

    if (this.advanceSearchDrawer.jql) {
      dataSource = dataSource.replace('$jql', JSON.stringify(this.advanceSearchDrawer.jql).replaceAll('"', '\\"'));
      if (this.advanceSearchDrawer.jqlAssetType)
        dataSource = dataSource.replace('$jqlAssetType', this.advanceSearchDrawer.jqlAssetType);
    } else {
      dataSource = dataSource.replace('$jql', '');
      dataSource = dataSource.replace('$jqlAssetType', '');
    }

    if (!this.hubConfig.GroupByField) dataSource = dataSource.replace('$groupBy', '');
    else dataSource = dataSource.replace('$groupBy', this.hubConfig.GroupByField);

    if (this.basicSearchValue && !this.advanceSearchDrawer.jql)
      dataSource = dataSource.replace('$basicSearch', this.basicSearchValue);
    else dataSource = dataSource.replace('$basicSearch', '');

    return dataSource;
  };

  loadData = async () => {
    this.isLoading = true;
    let dataSource = this.prepareDataSource();

    const graphQlService = new GraphQlService();
    const dataResponse = await graphQlService.get(dataSource);
    if (dataResponse.errors) graphQlService.displayErrors(dataResponse.errors);
    else {
      this.data = dataResponse.data;
      this.isLoading = false;
    }
  };

  loadGroupedData = async (groupByAssetDefField, groupByValue) => {
    const graphQlService = new GraphQlService();
    const dataResponse = await graphQlService.get(
      `{containers(where:{path:"${
        groupByAssetDefField.dataIndex === 'payload'
          ? `payload.${groupByAssetDefField.dataIndexField}`
          : groupByAssetDefField.dataIndexField
      }",comparison:EQUAL,value:"${groupByValue}"}){id,rootId,payload,name,rootId,createdBy{username},createdDateTime,riskStatus{icon,name,color,statusId,order}}}`
    );
    if (dataResponse.errors) graphQlService.displayErrors(dataResponse.errors);

    return dataResponse.data.containers
      .filter((x) => x)
      .map((x) => ({ ...x, payload: JSON.parse(x.payload || '{}') }))
      .filter((x) => x.payload && x.payload.Id);
  };

  downloadExcel = async () => {
    this.isLoading = true;
    let dataSource = this.prepareDataSource();

    var conditionsStartIndex = dataSource.indexOf('(');
    var conditionsEndIndex = dataSource.indexOf(')', conditionsStartIndex);

    const graphQlService = new GraphQlService();
    const dataResponse = await graphQlService.get(
      `{ containersToExcel${
        dataSource.substring(conditionsStartIndex, conditionsEndIndex) + `, uiHubId: "${this.hubId}")`
      } }`
    );
    if (dataResponse.errors) graphQlService.displayErrors(dataResponse.errors);
    else {
      console.log('dopwnload excel result', dataResponse);

      var a = document.createElement('a'); //Create <a>
      a.href = 'data:application/octet-stream;base64,' + dataResponse.data.containersToExcel;
      a.download = 'Export.xlsx';
      a.click();
      this.isLoading = false;
    }
  };

  remove = (id) => {
    this.containersApi.delete(id).then((r) => {
      message.success(i18next.t('documents.removeDocMessage'));
      this.loadData();
    });
  };
}

export default new ViewGeneratorStore();
